
import React, { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  FaPowerOff,
  FaUser
} from 'react-icons/fa'

import LZString from 'lz-string'

import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

import { CardsTemplateBuilder } from './deps/OpenSankey+/deps/OpenSankey/welcome/ModalWelcome'
import { Type_AdditionalMenus } from './deps/OpenSankey+/deps/OpenSankey/types/TypesOS'

import { initializeAdditionalMenusOSP } from './deps/OpenSankey+/OSPModule'

import { loginOut } from './components/Login/LoginFunctions'
import { Type_JSON } from './deps/OpenSankey+/deps/OpenSankey/types/Utils'
import { Class_ApplicationDataSA } from './ApplicationData'
import ExempleItem from './deps/OpenSankey+/deps/OpenSankey/welcome/MenuExamples'
import { app_name_opensankeyplus } from './components/Register/LicenseFunctions'

export const initializeApplicationDataSA = (
  new_data_app: Class_ApplicationDataSA,
  initial_data: Type_JSON | undefined,

) => {
  // Read data from cache if it exist
  if (initial_data !== undefined) {
    new_data_app.fromJSON(initial_data)
  }

  // Search if we have token of connexion into session storage
  const storage_token = LZString.decompress(sessionStorage.getItem('token') as string) as string
  const storage_token_osp = LZString.decompress(sessionStorage.getItem(app_name_opensankeyplus) as string) as string

  if (storage_token !== null && storage_token !== '') {
    const d_t = JSON.parse(storage_token)
    // If we have free token then activate free acount functionalities
    if (d_t === true) {
      new_data_app.activateFreeAccount()
    }
  }

  if (storage_token_osp !== null && storage_token_osp !== '') {
    const d_t = JSON.parse(storage_token_osp)
    // If we have osp token then activate Sankey plus functionalities
    if (d_t === true) {
      new_data_app.activateSankeyPlus()
    }
  }

  return new_data_app
}

export type ExempleMenuTypes = { [_: string]: ExempleMenuTypes | string[] }

type FType_InitializeAdditionalMenusSA = (
  additional_menus: Type_AdditionalMenus,
  new_data: Class_ApplicationDataSA,
  example_menu: ExempleMenuTypes,
  formations_menu: ExempleMenuTypes,
  reinitialization: () => void
) => void

/**
 * Since AdditionalMenus is an OS var specially created to add external element in menus
 *  we don't have to recast initializeAdditionalMenusType for more var or overwritting parameter types
 * @param {*} additionalMenus
 * @param {*} new_data_app
 */
export const initializeAdditionalMenusSA: FType_InitializeAdditionalMenusSA = (
  additionalMenus,
  new_data_app,
  example_menu,
  formations_menu,
  reinitialization
) => {

  // No initialisation if static --------------------------------------------------------

  if (new_data_app.is_static) {
    return
  }

  // OpenSankey+ initialisation ----------------------------------------------------------

  initializeAdditionalMenusOSP(
    additionalMenus,
    new_data_app
  )

  // New modules -------------------------------------------------------------------------

  additionalMenus.additional_nav_item.push(
    <UserPagesButtons
      new_data_app={new_data_app}
    />
  )

  additionalMenus.formations_menu = formations_menu

  additionalMenus.externale_navbar_item['demo'] = <ExempleItem
    new_data={new_data_app}
    exemple_menu={example_menu}
    current_path={''}
    launch={new_data_app.processFunction.launch}
    Reinitialization={reinitialization}
    initial_list={true}
  />

  additionalMenus.cards_template = CardsTemplateBuilder(new_data_app)
}

type FCType_UserPagesButtons = {
  new_data_app: Class_ApplicationDataSA
}

const UserPagesButtons: FunctionComponent<FCType_UserPagesButtons> = (
  { new_data_app }
) => {
  // Traduction
  const { t } = new_data_app

  // If windowSankey.SankeyToolsStatic is at true : we don't use the function useNavigate because we can't it use this function outside BrowserRouter
  // and if the app is in publication mode we aren't in one
  const navigate = useNavigate()
  const returnToApp = () => {
    navigate('/')
    new_data_app.menu_configuration.updateComponentsRelatedToSA()
  }

  const [count, setCount] = useState(0)
  const refreshThis = () => {
    setCount(count + 1)
  }
  new_data_app.menu_configuration.ref_to_additional_menus_updater.current = refreshThis

  const indicateSankeyToSaveInCache = () => new_data_app.menu_configuration.ref_to_save_in_cache_indicator.current(false)

  // Either create a menu to select where we navigate to (login or register account)
  // or add a button to navigate to
  const btn_navigate_to_login_register_dashboard = !new_data_app.has_free_account ? <Menu
    variant='menu_button_subnav_account_style'
    placement='bottom-end'
  >
    <MenuButton>
      <Box
        gridColumn='1'
        gridRow='1'
        justifySelf='end'
      >
        <FaUser
          style={{ 'height': '2rem', 'width': '2rem' }}
        />
      </Box>
      <Box
        gridColumn='2'
        gridRow='1'
        height='1rem'
        width='1rem'
        alignSelf='end'
      >
        <ChevronDownIcon
          style={{ 'height': '1rem', 'width': '1rem' }}
        />
      </Box>
    </MenuButton>
    <MenuList>
      <MenuItem
        onClick={() => {
          // applicationData.function_on_wait.current = () => {
          //   localStorage.setItem('data', LZString.compress(JSON.stringify((applicationData as suiteApplicationDataType).master_data)))
          //   localStorage.setItem('last_save', 'true')
          //   new_data_app.menu_configuration.ref_to_save_in_cache_indicator.current(true)
          //   navigate('/login')
          // }
          // dict_hook_ref_setter_show_dialog_components.ref_lauchToast.current()
          new_data_app.menu_configuration.function_on_wait.current = () => {
            indicateSankeyToSaveInCache()
            navigate('/login')
          }
          new_data_app.menu_configuration.ref_trigger_waiting_spinner_toast.current({ success: 'Layout Updated' })
        }}
      >
        {t('connect')}
      </MenuItem>
      <MenuItem
        onClick={() => navigate('/register')}
      >
        {t('UserPages.to_reg')}
      </MenuItem>
    </MenuList>
  </Menu> : <Box
    alignSelf='center'
    justifySelf='center'
    display='grid'
    gridTemplateColumns='1fr 1fr'
    gridColumnGap='0.25rem'
  >
    <Button
      variant={'menutop_button_goto_dashboard'}
      onClick={() => {
        // applicationData.function_on_wait.current = () => {
        //   localStorage.setItem('data', LZString.compress(JSON.stringify((applicationData as suiteApplicationDataType).master_data)))
        //   localStorage.setItem('last_save', 'true')
        //   new_data_app.menu_configuration.ref_to_save_in_cache_indicator.current(true)
        //   navigate('/dashboard')
        // }
        // dict_hook_ref_setter_show_dialog_components.ref_lauchToast.current()
        new_data_app.menu_configuration.function_on_wait.current = () => {
          indicateSankeyToSaveInCache()
          navigate('/dashboard')
        }
        new_data_app.menu_configuration.ref_trigger_waiting_spinner_toast.current({ success: 'Layout Updated' })
      }}>
      <FaUser />
    </Button>
    <Button
      variant='menutop_button_logout'
      onClick={() => loginOut(
        () => { new_data_app.unsetTokens() },
        returnToApp
      )}>
      <FaPowerOff />
    </Button>
  </Box>

  return btn_navigate_to_login_register_dashboard
}